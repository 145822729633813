.nav-menu {
	position: absolute;
	top: 100%;
	right: 1rem;
	width: 322/16+rem;
	max-height: calc(100vh - 100rem/16);
	overflow: auto;
	border-radius: .5rem;
	border: 1/16+rem solid $mercury;
	box-shadow: 0 2px 10px rgba(0,0,0,.2);
	background: #fff;

	&._hidden {
		display: none;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		padding: 28/16+rem;
	}

	&__item {
		flex: 0 1 33.333%;
		min-width: 33.333%;
		max-width: 33.333%;
		height: 100/16+rem;
		padding: 1/16+rem;
	}

	&__link {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 8/16+rem 2/16+rem;
		padding: 0;
		border: none;
		background: none;
		text-decoration: none;

		&:hover {
			border: 1px solid $mercury;
			border-radius: 2px;
			margin: 7px 1px;
		}
	}

	&__image {
		align-self: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 5/16+rem;
		margin-bottom: 8/16+rem;
		width: 48/16+rem;
		height: 48/16+rem;
		border-radius: 50%;

		img {
			flex: 0 1 100%;
			max-width: 100%;
			max-height: 100%;
		}
	}

	&__title {
		display: block;
		max-width: 100%;
		overflow: hidden;
		@include f1-400();
		font-size: 13/16+rem;
		line-height: 20/13+em;
		color: #000;
		text-align: center;
		white-space: nowrap;
		text-overflow: ellipsis;

		&._tt-u {
			text-transform: uppercase;
		}
	}

	&__link:hover & {

		&__title {
			white-space: normal;
		}
	}
}