@import "~factor/src/scss/variables";
@import "src/scss/components/variables";
@import "src/scss/components/mixins";
@import "src/scss/components/button";

.sidebar {
	position: fixed;
	z-index: 20;
	top: 70/16+rem;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	width: 320/16+rem;
	border-right: 1/16+rem solid $wild-sand;
	background: #fff;
	transition: transform linear 0.3s;

	&:global {

		&._hidden {
			transform: translateX(-100%);

			:local {

				.container {
					opacity: 0;
					pointer-events: none;
					transition: opacity 0s 0.3s;
				}

				.arrow {
					transition: transform 0.3s 0.3s;
					transform: translateX(30/16+rem);

					svg {
						transform: rotate(180deg);
					}
				}
			}
		}

		&._not-fixed {
			position: static;
		}

		&._right-sidebar {
			width: 320/16+rem;
			box-shadow: 0 0 .25rem 0 rgba($gray,.5);
		}
	}
}

.container {
	flex: 1 1 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	transition: opacity .25s .125s, transform .25s .125s;

	&:global {

		&._hidden {
			pointer-events: none;
			opacity: 0;
			transform: scale(.9);
			transition-delay: 0s;
		}
	}
}

.header {
	flex: 0 0 auto;
	padding: 1rem;
	border-bottom: 1/16+rem solid $wild-sand;

	& + .main {
		padding-top: 0;
	}
}

.main {
	flex: 1 1 auto;
	display: flex;
	align-items: stretch;
	//padding: 1rem;
	width: 100%;
	overflow: auto;
}

.content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
}

.arrow {
	@extend %button;
	position: absolute;
	z-index: 2;
	bottom: 12/16+rem;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30/16+rem;
	height: 30/16+rem;
	background: $melrose;
	cursor: pointer;
	transition: transform 0.3s, background 0.3s;

	&:hover {
		background: $indigo;
	}
}

.footer {
	flex: 0 0 auto;
}