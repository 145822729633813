@import "~factor/src/scss/variables";
@import "src/scss/components/variables";
@import "src/scss/components/mixins";
@import "src/scss/components/button";

.container {
	border-top: 1/16+rem solid $wild-sand;
}

.header {

	&Button {
		@extend %button;
		cursor: pointer;
		@extend %text;
		color: $indigo;
		text-transform: uppercase;
	}
}

.main {
	margin: .25rem .5rem 0;
	padding: 0 0 1rem;
}