.main-content {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 0 1rem;
	max-width: 100%;

	&__tabs {
		margin: 1rem 0;
	}

	&__controls {
		display: flex;
		align-items: center;
		margin: 0 -10/16+rem 1.5rem;
	}

	&__select {
		justify-content: flex-end;
		margin: 0 10/16+rem;

		&._columns {
			display: flex;
			margin-bottom: 0;
			@include flex-width(180);

			.select {

				&._opened {
					width: auto;
					min-width: 250/16+rem;
				}
			}
		}
	}

	&__btn {
		margin: 0 10/16+rem;
	}

	&__input-range {
		margin: 0 10/16+rem 24/16+rem;;
		width: 300/16+rem;
	}

	&__table-wrapper {
		align-self: flex-start;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		max-width: 100%;
	}
}