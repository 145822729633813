@import "~factor/src/scss/variables";
@import "src/scss/components/variables";
@import "src/scss/components/mixins";
@import "src/scss/components/button";

.container {
	padding-bottom: 1rem;
}

.modelBtn {
	margin-bottom: 1rem;
}

:global(.table) {

	.activeTh {
		@include td-width(140, 140);

		&:hover,
		&:global(._th-hover) {

			:global(.td-content) {
				background: $conflower-blue !important;
			}
		}

		:global(.td-content) {
			border-color: $indigo !important;
			background: $conflower-blue;
			color: #fff;
		}

		:global(.td-sorting) {

			path {
				fill: #fff;
			}
		}
	}

	.activeTd {
		@include td-width(140, 140);
	}

	td:global {

		&._servingMultiplier {

			.td-content-inner {
				width: 100%;
				overflow: visible;
			}
		}
	}
}

.select {

	&:global {

		&.select {
			width: 100%;

			.select {

				&__wrapper {
					position: static;
				}

				&__outer-wrapper,
				&__btn {
					height: 28/16+rem;
				}
			}
		}
	}
}