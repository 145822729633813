body {

	.nav-bar {
		z-index: 30;

		&__container {

			& > .btn-circle {
				margin-left: 0;
			}
		}

		&__circle-icon {
			display: flex;
			align-items: center;
		}

		&__wrapper {
			flex-grow: 1;
			display: flex;
			align-items: center;
			margin: 0 -14/16+rem;
			padding: 0 28/16+rem;
		}

		&__select {
			padding: 0 14/16+rem;
			width: 200/16+rem;

			.select {
				width: 100%;
			}
		}

		&__datespicker {
			display: flex;
			align-items: center;
			padding: 0 14/16+rem;
		}

		&__menu {
			margin-left: auto;
		}

		&__profile {

			&-logo {
				margin-right: 1rem;
				line-height: 0;

				img {
					max-width: 100%;
				}
			}

			&-avatar {
				width: 40/16+rem;
				min-width: 40/16+rem;
				height: 40/16+rem;
				border-radius: 50%;
				background-color: $gray;
			}
		}

		&__balance {
			display: flex;
			align-items: center;
			margin-left: auto;
			margin-right: 40/16+rem;

			&-info {
				padding-right: 14/16+rem;
				font-size: 1rem;
				line-height: 19/16+em;
				color: $conflower-blue;
			}
		}
	}
}