@font-face {
	font-family: 'Lato Light';
	src:
		url('fonts/lato-light.woff2') format('woff2'),
		url('fonts/lato-light.woff') format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Lato Regular';
	src:
		url('fonts/lato-regular.woff2') format('woff2'),
		url('fonts/lato-regular.woff') format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Lato Italic';
	src:
		url('fonts/lato-italic.woff2') format('woff2'),
		url('fonts/lato-italic.woff') format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Lato Medium';
	src:
		url('fonts/lato-medium.woff2') format('woff2'),
		url('fonts/lato-medium.woff') format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Lato Bold';
	src:
		url('fonts/lato-bold.woff2') format('woff2'),
		url('fonts/lato-bold.woff') format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Lato Black';
	src:
		url('fonts/lato-black.woff2') format('woff2'),
		url('fonts/lato-black.woff') format('woff');
	font-weight: 400;
}
