.dropdown {
	position: relative;
	z-index: 1;

	&._timezone {
		min-width: 120/16+rem;
		width: auto;
		height: 36/16+rem;
		background: #fff;
		z-index: 2;

		&._opened {
			min-width: 232/16+rem;
		}
	}

	&._timezone & {

		&__wrapper {
			border-radius: 4/16+rem;
			transition: box-shadow .25s;

			&:hover {
				box-shadow: 0 0 12/16+rem 0 rgba($silver-chalice,.5);
			}
		}
	}

	&._timezone._opened & {

		&__wrapper {
			box-shadow: 0 0 12/16+rem 0 rgba($silver-chalice,.5);
		}
	}

	&._timezone & {

		&__btn {
			display: flex;
			align-items: center;
			padding: 0 11/16+rem;
			height: 34/16+rem;
			border: 1/16+rem solid transparent;
			border-radius: 4/16+rem;
			cursor: pointer;
			@include f1-400();
			font-size: 12/16+rem;
			line-height: 15/12+em;
			color: $conflower-blue;
		}
	}

	&._timezone._opened & {

		&__btn {
			border-color: $alto;
			border-radius: 4/16+rem 4/16+rem 0 0;
		}
	}

	&__list-wrapper {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
	}

	&._opened & {

		&__list-wrapper {
			display: block;
		}
	}

	&._timezone & {

		&__list-wrapper {
			position: static;
			border: 1/16+rem solid $alto;
			border-top: none;
			border-radius: 0 0 4/16+rem 4/16+rem;
			background: #fff;
			transition: box-shadow .25s;
		}
	}

	&._timezone._opened & {

		&__list-wrapper {

		}
	}

	&__list {
		display: block;
	}

	&__option {
		@extend %button;
		display: block;
	}

	&._timezone & {

		&__option {
			@extend %button;
			display: block;
			padding: 0 11/16+rem;
			width: 100%;
			height: 28/16+rem;
			cursor: pointer;
			@extend %text;
			line-height: 28/16+rem;
			color: $gray;
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:hover {
				background: rgba($melrose,.3);
			}

			&._active {
				color: $chambray;
			}
		}
	}
}