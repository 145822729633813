@import "~factor/src/scss/variables";
@import "src/scss/components/variables";
@import "src/scss/components/mixins";
@import "src/scss/components/button";

.main {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 70/16+rem 0 0;
	min-width: 1280/16+rem;
	min-height: 100vh;
	transition: all 0.3s linear;

	@include over-xlg() {

		&:global {

			&._opened {
				padding-left: 320/16+rem;
			}
		}
	}
}