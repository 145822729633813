.skeleton-chart {
	width: 100%;
	height: 100%;
	padding: 1rem;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		border-left: 1/16+rem solid $mercury;
		border-bottom: 1/16+rem solid $mercury;
	}

	path {
		stroke-dasharray: 485;
		stroke-dashoffset: 485;

		animation: skeleton-chart-animation 2s infinite linear;
	}

	@keyframes skeleton-chart-animation {

		0% {
			stroke-dashoffset: 485;
		}

		50% {
			stroke-dashoffset: 0;
		}

		100% {
			stroke-dashoffset: -485;
		}
	}
}