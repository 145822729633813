.changing-value {
	height: 45/16+rem;
	border: 1/16+rem solid $alto;
	display: flex;
	align-items: center;
	justify-content: center;
	@include f1-400();
	@extend %text;

	&__old {
		margin-right: .5rem;
		@include flex-width(100);
		text-align: right;
	}

	&__difference {
		margin-right: .5rem;
		color: $lochinvar;

		&._minus {
			color: $persimmon;
		}
	}

	&__new {
		display: block;
		margin-left: .5rem;
		padding: 0;
		border: none;
		outline: none;
		box-shadow: none;
		@include flex-width(100);
		@include f1-700();
		@extend %text;
		color: #000;
	}
}