%text {
	font-size: 14/16+rem;
	line-height: 17/14+em;
}

//FONT-WEIGHTS
.fw-300 {
	@include f1-300();
}

.fw-700 {
	@include f1-700();
}

//COLORS
.c-persimmon {
	color: $persimmon;
}

.c-shamrock {
	color: $shamrock;
}

.c-lightning-yellow {
	color: $lightning-yellow;
}