.manage-campaigns {

	&__selected {
		margin-bottom: 21/16+rem;
		@extend %text;
		color: $conflower-blue;
	}

	&__row {
		margin-bottom: .5rem;
	}

	&__label {
		display: flex;
		align-items: center;
		flex: 0 0 107/16+rem;
		min-width: 107/16+rem;
		max-width: 107/16+rem;
		margin-right: 1rem;
		@include f1-400();
		font-size: 15/16+rem;
		line-height: 18/15+em;
		color: #000;
		white-space: nowrap;
	}

	&__control {
		flex: 1 1 auto;
	}

	&__tooltip {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 14/16+rem;
		min-width: 14/16+rem;
		height: 14/16+rem;
		border-radius: 50%;
		border: 1/16+rem solid $silver-chalice;
		margin-left: 11/16+rem;
		cursor: pointer;
		font-style: normal;

		&:before {
			content: '?';
			@include f1-700();
			font-size: 11px;
			color: $silver-chalice;
		}
	}
}