// COLORS
$dove-gray-1: #646464;
$shamrock: #3fd4ad;
$tree-poppy: #ffa300;
$mine-shaft-1: #1f1f1f;

$alto: #D8D8D8;
$nobel: #B5B5B5;

$tea-green-2: #DCF4CA;
$tusk: #E8F4CA;

$bitter-sweet: #FF6060;
$mona-lisa: #FF9090;

//
$persimmon: #ff5859;

$lochinvar: #2a8f74;
$turquoise: #31d3ae;
$fountain-blue: #5bc0a5;
$cruise: #b2eede;
$tea-green: #cbf4ca;

$buddha-gold: #d39603;
$sunglow: #ffc732;
$lightning-yellow: #ffbf1c;
$chalky: #f0d9a0;
$cumulus: #ffffda;
$tree-poppy: #ffa300;

$silver: #bdbdbd;

$heliotrope: #ca60ff;

$malibu: #6094ff;
$indigo: #4d5ecc;

// NEW
$boulder: #787878;
$gray: #818181;
$silver-chalice: #a2a2a2;
$alto: #e0e0e0;
$mercury: #e8e8e8;
$wild-sand: #f4f4f4;

// BREAKPOINTS
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1280;
$xlg: 1280;