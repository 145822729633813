.editable-text {
  width: 100%;
  position: relative;

  .text-field {
    &__input {
      font-size: 0.875rem;
      height: 100%;
      padding: 0;
    }

    &__base {
      margin-top: 0;

      &:after,
      &:before {
        bottom: -0.25rem;
      }

      &:after {
        border-color: $indigo;
      }
    }
  }
  
  .svg-icon {
    position: absolute;
    right: 0;
    width: 16px;
    height: 16px;
    fill: $indigo;
  }
}