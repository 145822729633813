.dialog {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	&._changing-value {
		min-width: 250/16+rem;
	}

	&__content {
		flex-grow: 1;

		h5 {
			@include f1-400();
			font-size: 22/16+rem;
			line-height: 27/22+em;
		}

		p {
			@extend %text;
		}

		.checkbox {

			&__label {
				@extend %text;
			}
		}
	}

	&._changing-value & {

		&__content {

			p {
				color: $gray;
				text-align: center;
			}
		}
	}

	&__input {
		margin: 1rem 0;
	}

	&._confirm {
		height: auto;
		min-width: 0;
		min-height: 0;
	}

	&._confirm & {

		&__title {
			display: flex;
			justify-content: space-between;
			@include f1-400();
			font-size: 22/16+rem;
			line-height: 27/22+em;
			color: #000;
		}

		&__p {
			@extend %text;
			color: $gray;
		}
	}
}