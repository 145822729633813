@import url(~factor/src/fonts/fonts.css);
@import-normalize;
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.chart-header__controls-btn, .dropdown__option, .dropdown._timezone .dropdown__option, .btn-select__title, .btn-select__btn {
  padding: 0;
  border: none;
  border-radius: 0;
  background: none; }
  .chart-header__controls-btn:focus, .dropdown__option:focus, .dropdown._timezone .dropdown__option:focus, .btn-select__title:focus, .btn-select__btn:focus {
    outline: none; }

.text-link, .btn-content, .select-btn .select:not(._opened) .select__value, .dropdown._timezone .dropdown__option, .changing-value, .changing-value__new, .ad-input, .range-wrapper__label, .range-wrapper__input, .btn-select__title, .btn-select__item, .btn-select .checkbox__label, .btn-select__buttons-title, .btn-select__btn, .dialog__content p, .dialog__content .checkbox__label, .dialog._confirm .dialog__p, .manage-campaigns__selected {
  font-family: 'Lato Regular', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.21429em; }

.title-main {
  font-family: 'Lato Black', sans-serif;
  font-weight: 400;
  font-size: 3rem;
  text-transform: capitalize;
  color: #787878; }

.title-main-subtitle {
  font-family: 'Lato Black', sans-serif;
  font-weight: 400;
  font-size: 2.25rem;
  text-transform: uppercase;
  color: #ff5859; }

.title-form {
  font-family: 'Lato Black', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #3fd4ad; }

.title-form-subtitle {
  font-family: 'Lato Light', sans-serif;
  font-weight: 400;
  font-size: 2rem;
  color: #000000; }

.title-card {
  font-family: 'Lato Medium', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.375em;
  color: #000000; }

.title-card-subtitle {
  font-family: 'Lato Regular', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #787878; }

.paragraph {
  margin-bottom: 1.5rem;
  font-family: 'Lato Regular', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.78947em;
  color: #787878; }
  .paragraph_large {
    font-size: 1.5rem; }

.text-link {
  color: #000;
  text-decoration: underline; }

._conflower-blue._filled.btn-round,
._conflower-blue._filled.btn-square, ._shamrock._filled.btn-round,
._shamrock._filled.btn-square, ._orange._filled.btn-round,
._orange._filled.btn-square, ._pomegranate._filled.btn-round,
._pomegranate._filled.btn-square, ._persimmon._filled.btn-round,
._persimmon._filled.btn-square, .btn-round._dodger-blue._filled, .select-btn .select:not(._opened) ._filled.select__btn,
.btn-square._dodger-blue._filled {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

._conflower-blue.btn-round:not(._filled):not([disabled]):hover,
._conflower-blue.btn-square:not(._filled):not([disabled]):hover, ._conflower-blue._filled.btn-round:not([disabled]):hover,
._conflower-blue._filled.btn-square:not([disabled]):hover, ._shamrock.btn-round:not(._filled):not([disabled]):hover,
._shamrock.btn-square:not(._filled):not([disabled]):hover, ._shamrock._filled.btn-round:not([disabled]):hover,
._shamrock._filled.btn-square:not([disabled]):hover, ._orange.btn-round:not(._filled):not([disabled]):hover,
._orange.btn-square:not(._filled):not([disabled]):hover, ._orange._filled.btn-round:not([disabled]):hover,
._orange._filled.btn-square:not([disabled]):hover, ._pomegranate.btn-round:not(._filled):not([disabled]):hover,
._pomegranate.btn-square:not(._filled):not([disabled]):hover, ._persimmon.btn-round:not(._filled):not([disabled]):hover,
._persimmon.btn-square:not(._filled):not([disabled]):hover, ._pomegranate._filled.btn-round:not([disabled]):hover,
._pomegranate._filled.btn-square:not([disabled]):hover, ._persimmon._filled.btn-round:not([disabled]):hover,
._persimmon._filled.btn-square:not([disabled]):hover, .btn-round._dodger-blue:not(._filled):not([disabled]):hover, .select-btn .select:not(._opened) .select__btn:not(._filled):not([disabled]):hover,
.btn-square._dodger-blue:not(._filled):not([disabled]):hover, .btn-round._dodger-blue._filled:not([disabled]):hover, .select-btn .select:not(._opened) ._filled.select__btn:not([disabled]):hover,
.btn-square._dodger-blue._filled:not([disabled]):hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

._conflower-blue.btn-round:not(._filled):not([disabled]):hover:active,
._conflower-blue.btn-square:not(._filled):not([disabled]):hover:active, ._conflower-blue._filled.btn-round:not([disabled]):hover:active,
._conflower-blue._filled.btn-square:not([disabled]):hover:active, ._shamrock.btn-round:not(._filled):not([disabled]):hover:active,
._shamrock.btn-square:not(._filled):not([disabled]):hover:active, ._shamrock._filled.btn-round:not([disabled]):hover:active,
._shamrock._filled.btn-square:not([disabled]):hover:active, ._orange.btn-round:not(._filled):not([disabled]):hover:active,
._orange.btn-square:not(._filled):not([disabled]):hover:active, ._orange._filled.btn-round:not([disabled]):hover:active,
._orange._filled.btn-square:not([disabled]):hover:active, ._pomegranate.btn-round:not(._filled):not([disabled]):hover:active,
._pomegranate.btn-square:not(._filled):not([disabled]):hover:active, ._persimmon.btn-round:not(._filled):not([disabled]):hover:active,
._persimmon.btn-square:not(._filled):not([disabled]):hover:active, ._pomegranate._filled.btn-round:not([disabled]):hover:active,
._pomegranate._filled.btn-square:not([disabled]):hover:active, ._persimmon._filled.btn-round:not([disabled]):hover:active,
._persimmon._filled.btn-square:not([disabled]):hover:active, .btn-round._dodger-blue:not(._filled):not([disabled]):hover:active, .select-btn .select:not(._opened) .select__btn:not(._filled):not([disabled]):hover:active,
.btn-square._dodger-blue:not(._filled):not([disabled]):hover:active, .btn-round._dodger-blue._filled:not([disabled]):hover:active, .select-btn .select:not(._opened) ._filled.select__btn:not([disabled]):hover:active,
.btn-square._dodger-blue._filled:not([disabled]):hover:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

@keyframes hover-ripples {
  0% {
    top: -0.1875em;
    left: -0.1875em;
    right: -0.1875em;
    bottom: -0.1875em;
    opacity: 1; }
  100% {
    top: -0.8125em;
    left: -0.8125em;
    right: -0.8125em;
    bottom: -0.8125em;
    opacity: 0; } }

.btn-round, .select-btn .select:not(._opened) .select__btn,
.btn-square {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 7.125em;
  height: 1.5625em;
  padding: 0 2em;
  outline: none;
  background: #fff;
  transition: border 0.25s, box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), background 0.25s, opacity 0.25s, color 0.25s;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  white-space: nowrap; }
  .btn-round:focus, .select-btn .select:not(._opened) .select__btn:focus,
  .btn-square:focus {
    outline: none; }
  .btn-round[class*="btn-"][disabled], .select-btn .select:not(._opened) .select__btn[class*="btn-"][disabled],
  .btn-square[class*="btn-"][disabled] {
    opacity: .5;
    cursor: default; }
  ._conflower-blue.btn-round:not(._filled), .select-btn .select:not(._opened) ._conflower-blue.select__btn:not(._filled),
  ._conflower-blue.btn-square:not(._filled) {
    border: 0.0625em solid #6075ff;
    background: transparent;
    color: #6075ff; }
    ._conflower-blue.btn-round:not(._filled):not([disabled]):hover, .select-btn .select:not(._opened) ._conflower-blue.select__btn:not(._filled):not([disabled]):hover,
    ._conflower-blue.btn-square:not(._filled):not([disabled]):hover {
      border-color: #4d5ecc;
      background: transparent;
      color: #4d5ecc; }
      ._conflower-blue.btn-round:not(._filled):not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._conflower-blue.select__btn:not(._filled):not([disabled]):hover._ripple-hover:before,
      ._conflower-blue.btn-square:not(._filled):not([disabled]):hover._ripple-hover:before {
        content: '';
        position: absolute;
        top: -0.1875em;
        left: -0.1875em;
        right: -0.1875em;
        bottom: -0.1875em;
        border-radius: 0.1875em;
        border: 0.0625em solid #4d5ecc;
        pointer-events: none;
        animation: hover-ripples 1s forwards; }
  ._conflower-blue._filled.btn-round, .select-btn .select:not(._opened) ._conflower-blue._filled.select__btn,
  ._conflower-blue._filled.btn-square {
    border: none;
    background: #6075ff;
    color: #fff; }
    ._conflower-blue._filled.btn-round:not([disabled]):hover, .select-btn .select:not(._opened) ._conflower-blue._filled.select__btn:not([disabled]):hover,
    ._conflower-blue._filled.btn-square:not([disabled]):hover {
      border-color: #fff;
      background: #4d5ecc;
      color: #fff; }
      ._conflower-blue._filled.btn-round:not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._conflower-blue._filled.select__btn:not([disabled]):hover._ripple-hover:before,
      ._conflower-blue._filled.btn-square:not([disabled]):hover._ripple-hover:before {
        content: '';
        position: absolute;
        top: -0.1875em;
        left: -0.1875em;
        right: -0.1875em;
        bottom: -0.1875em;
        border-radius: 0.1875em;
        border: 0.0625em solid #fff;
        pointer-events: none;
        animation: hover-ripples 1s forwards; }
  ._shamrock.btn-round:not(._filled), .select-btn .select:not(._opened) ._shamrock.select__btn:not(._filled),
  ._shamrock.btn-square:not(._filled) {
    border: 0.0625em solid #3fd4ad;
    background: transparent;
    color: #3fd4ad; }
    ._shamrock.btn-round:not(._filled):not([disabled]):hover, .select-btn .select:not(._opened) ._shamrock.select__btn:not(._filled):not([disabled]):hover,
    ._shamrock.btn-square:not(._filled):not([disabled]):hover {
      border-color: #0FB68A;
      background: transparent;
      color: #0FB68A; }
      ._shamrock.btn-round:not(._filled):not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._shamrock.select__btn:not(._filled):not([disabled]):hover._ripple-hover:before,
      ._shamrock.btn-square:not(._filled):not([disabled]):hover._ripple-hover:before {
        content: '';
        position: absolute;
        top: -0.1875em;
        left: -0.1875em;
        right: -0.1875em;
        bottom: -0.1875em;
        border-radius: 0.1875em;
        border: 0.0625em solid #0FB68A;
        pointer-events: none;
        animation: hover-ripples 1s forwards; }
  ._shamrock._filled.btn-round, .select-btn .select:not(._opened) ._shamrock._filled.select__btn,
  ._shamrock._filled.btn-square {
    border: none;
    background: #3fd4ad;
    color: #fff; }
    ._shamrock._filled.btn-round:not([disabled]):hover, .select-btn .select:not(._opened) ._shamrock._filled.select__btn:not([disabled]):hover,
    ._shamrock._filled.btn-square:not([disabled]):hover {
      border-color: #fff;
      background: #0FB68A;
      color: #fff; }
      ._shamrock._filled.btn-round:not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._shamrock._filled.select__btn:not([disabled]):hover._ripple-hover:before,
      ._shamrock._filled.btn-square:not([disabled]):hover._ripple-hover:before {
        content: '';
        position: absolute;
        top: -0.1875em;
        left: -0.1875em;
        right: -0.1875em;
        bottom: -0.1875em;
        border-radius: 0.1875em;
        border: 0.0625em solid #fff;
        pointer-events: none;
        animation: hover-ripples 1s forwards; }
  ._orange.btn-round:not(._filled), .select-btn .select:not(._opened) ._orange.select__btn:not(._filled),
  ._orange.btn-square:not(._filled) {
    border: 0.0625em solid #ffa300;
    background: transparent;
    color: #ffa300; }
    ._orange.btn-round:not(._filled):not([disabled]):hover, .select-btn .select:not(._opened) ._orange.select__btn:not(._filled):not([disabled]):hover,
    ._orange.btn-square:not(._filled):not([disabled]):hover {
      border-color: #BB7C0D;
      background: transparent;
      color: #BB7C0D; }
      ._orange.btn-round:not(._filled):not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._orange.select__btn:not(._filled):not([disabled]):hover._ripple-hover:before,
      ._orange.btn-square:not(._filled):not([disabled]):hover._ripple-hover:before {
        content: '';
        position: absolute;
        top: -0.1875em;
        left: -0.1875em;
        right: -0.1875em;
        bottom: -0.1875em;
        border-radius: 0.1875em;
        border: 0.0625em solid #BB7C0D;
        pointer-events: none;
        animation: hover-ripples 1s forwards; }
  ._orange._filled.btn-round, .select-btn .select:not(._opened) ._orange._filled.select__btn,
  ._orange._filled.btn-square {
    border: none;
    background: #ffa300;
    color: #fff; }
    ._orange._filled.btn-round:not([disabled]):hover, .select-btn .select:not(._opened) ._orange._filled.select__btn:not([disabled]):hover,
    ._orange._filled.btn-square:not([disabled]):hover {
      border-color: #fff;
      background: #BB7C0D;
      color: #fff; }
      ._orange._filled.btn-round:not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._orange._filled.select__btn:not([disabled]):hover._ripple-hover:before,
      ._orange._filled.btn-square:not([disabled]):hover._ripple-hover:before {
        content: '';
        position: absolute;
        top: -0.1875em;
        left: -0.1875em;
        right: -0.1875em;
        bottom: -0.1875em;
        border-radius: 0.1875em;
        border: 0.0625em solid #fff;
        pointer-events: none;
        animation: hover-ripples 1s forwards; }
  ._pomegranate.btn-round:not(._filled), .select-btn .select:not(._opened) ._pomegranate.select__btn:not(._filled),
  ._pomegranate.btn-square:not(._filled), ._persimmon.btn-round:not(._filled), .select-btn .select:not(._opened) ._persimmon.select__btn:not(._filled),
  ._persimmon.btn-square:not(._filled) {
    border: 0.0625em solid #f44336;
    background: transparent;
    color: #f44336; }
    ._pomegranate.btn-round:not(._filled):not([disabled]):hover, .select-btn .select:not(._opened) ._pomegranate.select__btn:not(._filled):not([disabled]):hover,
    ._pomegranate.btn-square:not(._filled):not([disabled]):hover, ._persimmon.btn-round:not(._filled):not([disabled]):hover, .select-btn .select:not(._opened) ._persimmon.select__btn:not(._filled):not([disabled]):hover,
    ._persimmon.btn-square:not(._filled):not([disabled]):hover {
      border-color: #D93536;
      background: transparent;
      color: #D93536; }
      ._pomegranate.btn-round:not(._filled):not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._pomegranate.select__btn:not(._filled):not([disabled]):hover._ripple-hover:before,
      ._pomegranate.btn-square:not(._filled):not([disabled]):hover._ripple-hover:before, ._persimmon.btn-round:not(._filled):not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._persimmon.select__btn:not(._filled):not([disabled]):hover._ripple-hover:before,
      ._persimmon.btn-square:not(._filled):not([disabled]):hover._ripple-hover:before {
        content: '';
        position: absolute;
        top: -0.1875em;
        left: -0.1875em;
        right: -0.1875em;
        bottom: -0.1875em;
        border-radius: 0.1875em;
        border: 0.0625em solid #D93536;
        pointer-events: none;
        animation: hover-ripples 1s forwards; }
  ._pomegranate._filled.btn-round, .select-btn .select:not(._opened) ._pomegranate._filled.select__btn,
  ._pomegranate._filled.btn-square, ._persimmon._filled.btn-round, .select-btn .select:not(._opened) ._persimmon._filled.select__btn,
  ._persimmon._filled.btn-square {
    border: none;
    background: #f44336;
    color: #fff; }
    ._pomegranate._filled.btn-round:not([disabled]):hover, .select-btn .select:not(._opened) ._pomegranate._filled.select__btn:not([disabled]):hover,
    ._pomegranate._filled.btn-square:not([disabled]):hover, ._persimmon._filled.btn-round:not([disabled]):hover, .select-btn .select:not(._opened) ._persimmon._filled.select__btn:not([disabled]):hover,
    ._persimmon._filled.btn-square:not([disabled]):hover {
      border-color: #fff;
      background: #D93536;
      color: #fff; }
      ._pomegranate._filled.btn-round:not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._pomegranate._filled.select__btn:not([disabled]):hover._ripple-hover:before,
      ._pomegranate._filled.btn-square:not([disabled]):hover._ripple-hover:before, ._persimmon._filled.btn-round:not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._persimmon._filled.select__btn:not([disabled]):hover._ripple-hover:before,
      ._persimmon._filled.btn-square:not([disabled]):hover._ripple-hover:before {
        content: '';
        position: absolute;
        top: -0.1875em;
        left: -0.1875em;
        right: -0.1875em;
        bottom: -0.1875em;
        border-radius: 0.1875em;
        border: 0.0625em solid #fff;
        pointer-events: none;
        animation: hover-ripples 1s forwards; }
  ._md.btn-round, .select-btn .select:not(._opened) ._md.select__btn,
  ._md.btn-square {
    padding: 0 2.5em;
    min-width: 4.8125em;
    height: 1.875em; }
  ._lg.btn-round, .select-btn .select:not(._opened) ._lg.select__btn,
  ._lg.btn-square {
    padding: 0 2.5em;
    min-width: 5.9375em;
    height: 2.5em; }

.btn-round__prefix,
.btn-square__prefix {
  position: absolute;
  top: 50%;
  left: 0.625em;
  width: 0.75em;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
  font-size: 1.28571em; }

.btn-round._md .btn-round__prefix, .select-btn .select:not(._opened) ._md.select__btn .btn-round__prefix,
.btn-round._lg .btn-round__prefix,
.select-btn .select:not(._opened) ._lg.select__btn .btn-round__prefix,
.btn-square._md .btn-round__prefix,
.btn-square._lg .btn-round__prefix, .btn-round._md .btn-square__prefix, .select-btn .select:not(._opened) ._md.select__btn .btn-square__prefix,
.btn-round._lg .btn-square__prefix,
.select-btn .select:not(._opened) ._lg.select__btn .btn-square__prefix,
.btn-square._md
.btn-square__prefix,
.btn-square._lg
.btn-square__prefix {
  left: 1.11111em;
  transform: translateX(-50%) translateY(-50%); }

.btn-round._md .btn-content, .select-btn .select:not(._opened) ._md.select__btn .btn-content, .btn-round._md .select-btn .select:not(._opened) .select__value, .select-btn .select:not(._opened) .btn-round._md .select__value, .select-btn .select:not(._opened) ._md.select__btn .select__value, .btn-round._lg .btn-content, .select-btn .select:not(._opened) ._lg.select__btn .btn-content, .btn-round._lg .select-btn .select:not(._opened) .select__value, .select-btn .select:not(._opened) .btn-round._lg .select__value, .select-btn .select:not(._opened) ._lg.select__btn .select__value,
.btn-square._md .btn-content,
.btn-square._md .select-btn .select:not(._opened) .select__value,
.select-btn .select:not(._opened) .btn-square._md .select__value,
.btn-square._lg .btn-content,
.btn-square._lg .select-btn .select:not(._opened) .select__value,
.select-btn .select:not(._opened) .btn-square._lg .select__value {
  padding-bottom: 0.0625rem; }

.btn-square {
  border-radius: 0.25em; }

.btn-round, .select-btn .select:not(._opened) .select__btn {
  border-radius: 0.78125em; }
  .btn-round._md, .select-btn .select:not(._opened) ._md.select__btn {
    border-radius: 0.9375em; }
  .btn-round._lg, .select-btn .select:not(._opened) ._lg.select__btn {
    border-radius: 1.25em; }

.btn-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
  height: 2.5em;
  width: 2.5em;
  border: 0.0625em solid #a2a2a2;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  font-size: 1rem; }
  .btn-circle:focus {
    outline: none; }
  .btn-circle svg {
    fill: #a2a2a2;
    width: 1.5em;
    height: auto;
    position: relative; }
  .btn-circle._size-14 svg {
    width: 0.875em; }
  .btn-circle._size-15 svg {
    width: 0.9375em; }
  .btn-circle._size-16 svg {
    width: 1em; }
  .btn-circle._size-17 svg {
    width: 1.0625em; }
  .btn-circle._size-18 svg {
    width: 1.125em; }
  .btn-circle._size-19 svg {
    width: 1.1875em; }
  .btn-circle._size-20 svg {
    width: 1.25em; }
  .btn-circle._size-21 svg {
    width: 1.3125em; }
  .btn-circle._size-22 svg {
    width: 1.375em; }
  .btn-circle._size-23 svg {
    width: 1.4375em; }
  .btn-circle._size-24 svg {
    width: 1.5em; }
  .btn-circle:hover:not(._outline) {
    background-color: #a2a2a2; }
    .btn-circle:hover:not(._outline) svg {
      fill: #787878; }
  .btn-circle._outline {
    border: none; }
    .btn-circle._outline:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scale(0);
      opacity: 0;
      transition: transform .3s,opacity .3s;
      background-color: #f4f4f4;
      border-radius: 50%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .btn-circle._outline._inverse:before {
      background-color: #a2a2a2; }
    .btn-circle._outline:hover svg {
      fill: #787878; }
    .btn-circle._outline:hover:before {
      transform: scale(1);
      opacity: 1; }
    .btn-circle._outline:active {
      border: 0.0625em solid #787878; }
  .btn-circle._inverse {
    border-color: #787878;
    background-color: transparent; }
    .btn-circle._inverse svg {
      fill: #ffffff; }
    .btn-circle._inverse:hover:not(._outline) {
      background-color: #a2a2a2; }
      .btn-circle._inverse:hover:not(._outline) svg {
        fill: #787878; }
  .btn-circle .btn-content, .btn-circle .select-btn .select:not(._opened) .select__value, .select-btn .select:not(._opened) .btn-circle .select__value {
    font-size: 1em; }

.btn-content, .select-btn .select:not(._opened) .select__value {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; }

@font-face {
  font-family: 'Lato Light';
  src: url("fonts/lato-light.woff2") format("woff2"), url("fonts/lato-light.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: 'Lato Regular';
  src: url("fonts/lato-regular.woff2") format("woff2"), url("fonts/lato-regular.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: 'Lato Italic';
  src: url("fonts/lato-italic.woff2") format("woff2"), url("fonts/lato-italic.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: 'Lato Medium';
  src: url("fonts/lato-medium.woff2") format("woff2"), url("fonts/lato-medium.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: 'Lato Bold';
  src: url("fonts/lato-bold.woff2") format("woff2"), url("fonts/lato-bold.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: 'Lato Black';
  src: url("fonts/lato-black.woff2") format("woff2"), url("fonts/lato-black.woff") format("woff");
  font-weight: 400; }

.text-link, .btn-content, .select-btn .select:not(._opened) .select__value, .dropdown._timezone .dropdown__option, .changing-value, .changing-value__new, .ad-input, .range-wrapper__label, .range-wrapper__input, .btn-select__title, .btn-select__item, .btn-select .checkbox__label, .btn-select__buttons-title, .btn-select__btn, .dialog__content p, .dialog__content .checkbox__label, .dialog._confirm .dialog__p, .manage-campaigns__selected {
  font-family: 'Lato Regular', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.21429em; }

.text-link, .btn-content, .select-btn .select:not(._opened) .select__value, .dropdown._timezone .dropdown__option, .changing-value, .changing-value__new, .ad-input, .range-wrapper__label, .range-wrapper__input, .btn-select__title, .btn-select__item, .btn-select .checkbox__label, .btn-select__buttons-title, .btn-select__btn, .dialog__content p, .dialog__content .checkbox__label, .dialog._confirm .dialog__p, .manage-campaigns__selected {
  font-size: 0.875rem;
  line-height: 1.21429em; }

.fw-300 {
  font-family: 'Lato Light', sans-serif;
  font-weight: 400; }

.fw-700 {
  font-family: 'Lato Bold', sans-serif;
  font-weight: 400; }

.c-persimmon {
  color: #ff5859; }

.c-shamrock {
  color: #3fd4ad; }

.c-lightning-yellow {
  color: #ffbf1c; }

* {
  box-sizing: border-box; }

html {
  margin: 0;
  font-size: 16px; }

body {
  margin: 0;
  font-family: 'Lato Regular', sans-serif;
  font-weight: 400; }

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.d-f {
  display: flex; }

.jc-sb {
  justify-content: space-between; }

.jc-fe {
  justify-content: flex-end; }

.ai-fs {
  align-items: flex-start; }

.ai-fe {
  align-items: flex-end; }

.ai-c {
  align-items: center; }

.ai-b {
  align-items: baseline; }

.select._opened {
  position: relative;
  z-index: 20; }

.select__value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.select._opened .select__btn {
  border-radius: 0.5625rem 0.5625rem 0 0; }

.select-btn .select {
  width: auto; }
  .select-btn .select:not(._opened) .select__indicator {
    display: none; }
  .select-btn .select__wrapper {
    position: static; }

.block {
  padding: .5rem;
  border-radius: .25rem;
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5); }

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1.25rem; }
  .chart-header__controls {
    display: flex;
    align-items: center;
    width: 100%; }
    .chart-header__controls span {
      margin: 0 0.75rem;
      color: #232323; }
    .chart-header__controls-btn {
      cursor: pointer;
      font-family: 'Lato Regular', sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.1875em;
      color: #5874DB; }
  .chart-header__select {
    flex: 1 1 9.1875rem;
    max-width: 9.1875rem;
    min-width: 0; }
    .chart-header__select + .chart-header__select {
      margin-left: .5rem; }
    .chart-header__select .select {
      width: 100%; }
      .chart-header__select .select._opened {
        width: auto;
        min-width: 14.125rem; }
      .chart-header__select .select__option-label {
        font-family: 'Lato Regular', sans-serif;
        font-weight: 400; }
    .chart-header__select:last-child:not(:only-child) {
      display: flex;
      justify-content: flex-end; }

.chart-tooltip {
  position: absolute;
  z-index: 20;
  border-radius: .25rem;
  padding: 0.625rem 1.25rem;
  background: #4B5661;
  transform: translate(15px, -50%);
  transition: transform ease-out .5s; }
  .chart-tooltip:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 0.4375rem solid transparent;
    border-bottom: 0.4375rem solid transparent;
    border-right: .5rem solid #4B5661; }
  .chart-tooltip span {
    display: block; }
    .chart-tooltip span:nth-child(1) {
      margin-bottom: 0.4375rem;
      font-family: 'Lato Bold', sans-serif;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.25em;
      color: #BCC6D2; }
    .chart-tooltip span:nth-child(2) {
      font-family: 'Lato Bold', sans-serif;
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.2em;
      color: #E0E7F0;
      white-space: nowrap; }

.sort-th {
  position: relative;
  width: 100%; }
  .sort-th:before, .sort-th:after {
    content: '';
    position: absolute;
    right: 0;
    width: .5rem;
    height: 0.375rem;
    background: transparent url("/img/common/sorting-arrow-down.svg") no-repeat left top; }
  .sort-th:before {
    bottom: 50%;
    margin-bottom: 0.0625rem;
    transform: rotate(180deg); }
  .sort-th:after {
    top: 50%;
    margin-top: 0.0625rem; }
  .sort-th._down:before {
    display: none; }
  .sort-th._up:after {
    display: none; }

.dropdown {
  position: relative;
  z-index: 1; }
  .dropdown._timezone {
    min-width: 7.5rem;
    width: auto;
    height: 2.25rem;
    background: #fff;
    z-index: 2; }
    .dropdown._timezone._opened {
      min-width: 14.5rem; }
  .dropdown._timezone .dropdown__wrapper {
    border-radius: 0.25rem;
    transition: box-shadow .25s; }
    .dropdown._timezone .dropdown__wrapper:hover {
      box-shadow: 0 0 0.75rem 0 rgba(162, 162, 162, 0.5); }
  .dropdown._timezone._opened .dropdown__wrapper {
    box-shadow: 0 0 0.75rem 0 rgba(162, 162, 162, 0.5); }
  .dropdown._timezone .dropdown__btn {
    display: flex;
    align-items: center;
    padding: 0 0.6875rem;
    height: 2.125rem;
    border: 0.0625rem solid transparent;
    border-radius: 0.25rem;
    cursor: pointer;
    font-family: 'Lato Regular', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25em;
    color: #6075ff; }
  .dropdown._timezone._opened .dropdown__btn {
    border-color: #e0e0e0;
    border-radius: 0.25rem 0.25rem 0 0; }
  .dropdown__list-wrapper {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0; }
  .dropdown._opened .dropdown__list-wrapper {
    display: block; }
  .dropdown._timezone .dropdown__list-wrapper {
    position: static;
    border: 0.0625rem solid #e0e0e0;
    border-top: none;
    border-radius: 0 0 0.25rem 0.25rem;
    background: #fff;
    transition: box-shadow .25s; }
  .dropdown__list {
    display: block; }
  .dropdown__option {
    display: block; }
  .dropdown._timezone .dropdown__option {
    display: block;
    padding: 0 0.6875rem;
    width: 100%;
    height: 1.75rem;
    cursor: pointer;
    line-height: 1.75rem;
    color: #818181;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .dropdown._timezone .dropdown__option:hover {
      background: rgba(153, 167, 255, 0.3); }
    .dropdown._timezone .dropdown__option._active {
      color: #323c82; }

.btn-round._dodger-blue:not(._filled), .select-btn .select:not(._opened) .select__btn:not(._filled),
.btn-square._dodger-blue:not(._filled) {
  border: 0.0625em solid #3399ff;
  background: transparent;
  color: #3399ff; }
  .btn-round._dodger-blue:not(._filled):not([disabled]):hover, .select-btn .select:not(._opened) .select__btn:not(._filled):not([disabled]):hover,
  .btn-square._dodger-blue:not(._filled):not([disabled]):hover {
    border-color: #4d5ecc;
    background: transparent;
    color: #4d5ecc; }
    .btn-round._dodger-blue:not(._filled):not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) .select__btn:not(._filled):not([disabled]):hover._ripple-hover:before,
    .btn-square._dodger-blue:not(._filled):not([disabled]):hover._ripple-hover:before {
      content: '';
      position: absolute;
      top: -0.1875em;
      left: -0.1875em;
      right: -0.1875em;
      bottom: -0.1875em;
      border-radius: 0.1875em;
      border: 0.0625em solid #4d5ecc;
      pointer-events: none;
      animation: hover-ripples 1s forwards; }

.btn-round._dodger-blue._filled, .select-btn .select:not(._opened) ._filled.select__btn,
.btn-square._dodger-blue._filled {
  border: none;
  background: #3399ff;
  color: #fff; }
  .btn-round._dodger-blue._filled:not([disabled]):hover, .select-btn .select:not(._opened) ._filled.select__btn:not([disabled]):hover,
  .btn-square._dodger-blue._filled:not([disabled]):hover {
    border-color: #fff;
    background: #4d5ecc;
    color: #fff; }
    .btn-round._dodger-blue._filled:not([disabled]):hover._ripple-hover:before, .select-btn .select:not(._opened) ._filled.select__btn:not([disabled]):hover._ripple-hover:before,
    .btn-square._dodger-blue._filled:not([disabled]):hover._ripple-hover:before {
      content: '';
      position: absolute;
      top: -0.1875em;
      left: -0.1875em;
      right: -0.1875em;
      bottom: -0.1875em;
      border-radius: 0.1875em;
      border: 0.0625em solid #fff;
      pointer-events: none;
      animation: hover-ripples 1s forwards; }

.btn-round._dodger-blue .btn-round__prefix path, .select-btn .select:not(._opened) .select__btn .btn-round__prefix path,
.btn-round._dodger-blue .btn-square__prefix path,
.select-btn .select:not(._opened) .select__btn .btn-square__prefix path,
.btn-square._dodger-blue .btn-round__prefix path,
.btn-square._dodger-blue .btn-square__prefix path {
  fill: #3399ff;
  transition: fill .25s; }

.btn-round._dodger-blue:hover .btn-round__prefix path, .select-btn .select:not(._opened) .select__btn:hover .btn-round__prefix path,
.btn-round._dodger-blue:hover .btn-square__prefix path,
.select-btn .select:not(._opened) .select__btn:hover .btn-square__prefix path,
.btn-square._dodger-blue:hover .btn-round__prefix path,
.btn-square._dodger-blue:hover .btn-square__prefix path {
  fill: #4d5ecc; }

.btn-round._icon-right .btn-round__prefix, .select-btn .select:not(._opened) ._icon-right.select__btn .btn-round__prefix,
.btn-square._icon-right .btn-round__prefix, .btn-round._icon-right .btn-square__prefix, .select-btn .select:not(._opened) ._icon-right.select__btn .btn-square__prefix,
.btn-square._icon-right
.btn-square__prefix {
  left: auto;
  right: 0.625rem; }

.btn-close {
  position: relative;
  margin: 0;
  width: 0.875rem;
  height: 0.875rem;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer; }
  .btn-close:hover, .btn-close:focus {
    outline: none; }
  .btn-close:before, .btn-close:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.3125rem;
    height: 0.125rem;
    border-radius: 0.0625rem;
    background: #818181;
    transition: background .25s; }
  .btn-close:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg); }
  .btn-close:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg); }

.changing-value {
  height: 2.8125rem;
  border: 0.0625rem solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato Regular', sans-serif;
  font-weight: 400; }
  .changing-value__old {
    margin-right: .5rem;
    flex: 0 1 6.25rem;
    min-width: 6.25rem;
    max-width: 6.25rem;
    text-align: right; }
  .changing-value__difference {
    margin-right: .5rem;
    color: #2a8f74; }
    .changing-value__difference._minus {
      color: #ff5859; }
  .changing-value__new {
    display: block;
    margin-left: .5rem;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    flex: 0 1 6.25rem;
    min-width: 6.25rem;
    max-width: 6.25rem;
    font-family: 'Lato Bold', sans-serif;
    font-weight: 400;
    color: #000; }

.ad-input {
  padding: 0 0.375rem;
  height: 2.25rem;
  border-radius: 0.375rem;
  border: 0.0625rem solid #e0e0e0;
  font-family: 'Lato Light', sans-serif;
  font-weight: 400;
  color: #818181; }
  .ad-input:focus {
    outline: none; }

.range-wrapper__label {
  display: flex;
  align-items: center;
  margin-bottom: 0.4375rem;
  color: #a2a2a2; }

.range-wrapper__tooltip {
  margin-left: 0.25rem; }
  .range-wrapper__tooltip .tooltip {
    line-height: 0; }
    .range-wrapper__tooltip .tooltip__label {
      line-height: 1.21429em; }

.range-wrapper__main {
  display: flex;
  align-items: center;
  height: 2.25rem; }

.range-wrapper__input {
  flex: 0 1 3.125rem;
  min-width: 3.125rem;
  max-width: 3.125rem;
  margin-left: 0.625rem;
  height: 1.5625rem;
  border-radius: 0.125rem;
  border: 0.0625rem solid #d8d8d8;
  color: #818181;
  text-align: center; }
  .range-wrapper__input:focus {
    outline: none; }

.range__track {
  position: relative;
  height: 2.25rem;
  width: 100%;
  border-radius: 0;
  pointer-events: none; }
  .range__track:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 0.3125rem;
    width: 100%;
    background: #99a7ff;
    pointer-events: auto; }

.range__slider {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  border: 0.0625rem solid #99a7ff;
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(162, 162, 162, 0.5);
  transition: transform .25s;
  pointer-events: auto; }

.range__value {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato Regular', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.15;
  color: #000;
  letter-spacing: -.01em;
  text-align: center; }

.range__x {
  margin-bottom: 0.125rem;
  margin-right: 0.125rem;
  font-size: 0.75rem; }

body .table__top {
  display: none;
  border-bottom: none; }

body .table thead td .tooltip {
  margin-left: .25rem;
  line-height: 0; }
  body .table thead td .tooltip__label {
    line-height: 1; }
  body .table thead td .tooltip path {
    fill: #787878; }

body .table td._id {
  min-width: 5.625rem;
  width: 5.625rem;
  max-width: 5.625rem; }

body .table td._frequency-cap {
  min-width: 8.75rem;
  width: 8.75rem;
  max-width: 8.75rem; }

body .table td._total-spent {
  min-width: 9.375rem;
  width: 9.375rem;
  max-width: 9.375rem; }

body .table td._collapsed {
  min-width: 0rem;
  width: 0rem;
  max-width: 0rem;
  padding: 0;
  border: none; }

body .table td._max-bid .td-content-inner, body .table td._maxBid .td-content-inner, body .table td._frequency-cap .td-content-inner, body .table td._frequencyCap .td-content-inner, body .table td._bid-pacing .td-content-inner, body .table td._bidPacing .td-content-inner {
  overflow: visible; }

body .table td._exclude .td-content {
  justify-content: center; }
  body .table td._exclude .td-content .checkbox svg {
    margin: 0; }

body .table td._bidPacing .td-content {
  justify-content: center; }

.date-range-picker {
  z-index: 20; }

.btn-select {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .btn-select__title {
    position: relative;
    display: block;
    margin: -0.75rem -1rem 0;
    padding: 0.75rem 2rem 0.5625rem 0.8125rem;
    cursor: pointer;
    font-family: 'Lato Bold', sans-serif;
    font-weight: 400;
    text-align: left; }
    .btn-select__title:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
      border-bottom: 0.3125rem solid #818181;
      border-left: 0.3125rem solid transparent;
      border-right: 0.3125rem solid transparent; }
  .btn-select__search {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 -1rem 0.25rem;
    padding: 0 0.4375rem; }
  .btn-select__list {
    margin: 0 -1rem;
    padding-bottom: 0.625rem;
    border-bottom: 0.0625rem solid #818181; }
  .btn-select__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.8125rem;
    height: 1.75rem;
    cursor: pointer;
    font-family: 'Lato Light', sans-serif;
    font-weight: 400; }
    .btn-select__item:hover {
      background: #f4f4f4; }
    .btn-select__item._active {
      padding-right: 0.5625rem;
      background: #dfe4ff; }
    .btn-select__item svg {
      width: 1rem;
      height: auto; }
  .btn-select .checkbox__label {
    font-family: 'Lato Regular', sans-serif;
    font-weight: 400; }
  .btn-select__buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 -1rem;
    padding-top: 0.5rem; }
    .btn-select__buttons:not(:last-child) {
      padding-bottom: .5rem;
      border-bottom: 0.0625rem solid #818181; }
    .btn-select__buttons-title {
      margin-bottom: 0.625rem;
      padding: 0 0.8125rem;
      font-family: 'Lato Bold', sans-serif;
      font-weight: 400; }
  .btn-select__btn {
    padding: 0 0.8125rem;
    cursor: pointer;
    line-height: 2;
    color: #818181;
    text-align: left; }
    .btn-select__btn:not(:last-child) {
      margin-bottom: 0.3125rem; }
    .btn-select__btn:hover {
      background: #f4f4f4; }

.skeleton-chart {
  width: 100%;
  height: 100%;
  padding: 1rem; }
  .skeleton-chart svg {
    display: block;
    width: 100%;
    height: 100%;
    border-left: 0.0625rem solid #e8e8e8;
    border-bottom: 0.0625rem solid #e8e8e8; }
  .skeleton-chart path {
    stroke-dasharray: 485;
    stroke-dashoffset: 485;
    animation: skeleton-chart-animation 2s infinite linear; }

@keyframes skeleton-chart-animation {
  0% {
    stroke-dashoffset: 485; }
  50% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -485; } }

.dialog {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .dialog._changing-value {
    min-width: 15.625rem; }
  .dialog__content {
    flex-grow: 1; }
    .dialog__content h5 {
      font-family: 'Lato Regular', sans-serif;
      font-weight: 400;
      font-size: 1.375rem;
      line-height: 1.22727em; }
  .dialog._changing-value .dialog__content p {
    color: #818181;
    text-align: center; }
  .dialog__input {
    margin: 1rem 0; }
  .dialog._confirm {
    height: auto;
    min-width: 0;
    min-height: 0; }
  .dialog._confirm .dialog__title {
    display: flex;
    justify-content: space-between;
    font-family: 'Lato Regular', sans-serif;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.22727em;
    color: #000; }
  .dialog._confirm .dialog__p {
    color: #818181; }

.nav-menu {
  position: absolute;
  top: 100%;
  right: 1rem;
  width: 20.125rem;
  max-height: calc(100vh - 100rem/16);
  overflow: auto;
  border-radius: .5rem;
  border: 0.0625rem solid #e8e8e8;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: #fff; }
  .nav-menu._hidden {
    display: none; }
  .nav-menu__list {
    display: flex;
    flex-wrap: wrap;
    padding: 1.75rem; }
  .nav-menu__item {
    flex: 0 1 33.333%;
    min-width: 33.333%;
    max-width: 33.333%;
    height: 6.25rem;
    padding: 0.0625rem; }
  .nav-menu__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0.125rem;
    padding: 0;
    border: none;
    background: none;
    text-decoration: none; }
    .nav-menu__link:hover {
      border: 1px solid #e8e8e8;
      border-radius: 2px;
      margin: 7px 1px; }
  .nav-menu__image {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.3125rem;
    margin-bottom: 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%; }
    .nav-menu__image img {
      flex: 0 1 100%;
      max-width: 100%;
      max-height: 100%; }
  .nav-menu__title {
    display: block;
    max-width: 100%;
    overflow: hidden;
    font-family: 'Lato Regular', sans-serif;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.53846em;
    color: #000;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .nav-menu__title._tt-u {
      text-transform: uppercase; }
  .nav-menu__link:hover .nav-menu__title {
    white-space: normal; }

.filter__row {
  display: flex;
  align-items: center; }
  .filter__row._pl {
    padding-left: 2.75rem; }

.filter__left {
  flex: 1 1 auto;
  display: flex;
  align-items: center; }

.filter__right {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto; }
  .filter__right .filter__select {
    margin-left: 0.875rem; }

.filter__checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 2.75rem; }
  .filter__checkbox svg {
    margin: 0; }
  .filter__checkbox .sort-th {
    margin-left: .25rem;
    width: .5rem; }

.filter__search {
  margin-right: 0.8125rem;
  width: 10rem; }

.filter__select {
  flex: 1 1 10rem;
  padding-bottom: 1.5rem;
  max-width: 10rem;
  min-width: 0; }
  .filter__select._columns {
    display: flex;
    justify-content: flex-end;
    min-width: 10rem; }
    .filter__select._columns .select._opened {
      width: auto;
      min-width: 15.625rem; }
  .filter__select:not(:last-child) {
    margin-right: 0.75rem; }
  .filter__select .select {
    width: 100%; }
    .filter__select .select__btn-secondary {
      white-space: nowrap; }
  .filter__select._campaigns {
    min-width: 10rem; }
    .filter__select._campaigns .select._opened {
      width: auto;
      min-width: 14.125rem; }

.filter__btn-group {
  margin-right: 1.125rem; }

.filter__selected {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  font-family: 'Lato Regular', sans-serif;
  font-weight: 400;
  font-size: 1.0625rem;
  line-height: 1.23529em;
  color: #656565; }

.filter__remove {
  margin-right: 0.375rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  background: #e0e0e0; }
  .filter__remove:before, .filter__remove:after {
    width: 0.625rem;
    height: 0.0625rem;
    background: #fff;
    border-radius: 0.125rem; }

.filter__btn:not(:last-child) {
  margin-right: .5rem; }

body .nav-bar {
  z-index: 30; }
  body .nav-bar__container > .btn-circle {
    margin-left: 0; }
  body .nav-bar__circle-icon {
    display: flex;
    align-items: center; }
  body .nav-bar__wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin: 0 -0.875rem;
    padding: 0 1.75rem; }
  body .nav-bar__select {
    padding: 0 0.875rem;
    width: 12.5rem; }
    body .nav-bar__select .select {
      width: 100%; }
  body .nav-bar__datespicker {
    display: flex;
    align-items: center;
    padding: 0 0.875rem; }
  body .nav-bar__menu {
    margin-left: auto; }
  body .nav-bar__profile-logo {
    margin-right: 1rem;
    line-height: 0; }
    body .nav-bar__profile-logo img {
      max-width: 100%; }
  body .nav-bar__profile-avatar {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #818181; }
  body .nav-bar__balance {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 2.5rem; }
    body .nav-bar__balance-info {
      padding-right: 0.875rem;
      font-size: 1rem;
      line-height: 1.1875em;
      color: #6075ff; }

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  max-width: none;
  min-width: 80rem;
  min-height: 100vh;
  padding: 5.375rem 0 1rem; }

.dashboard {
  padding: 0 2rem; }
  .dashboard__header {
    margin-bottom: 0.75rem; }
  .dashboard__indicators {
    margin-bottom: 1.5rem; }
  .dashboard__charts {
    display: flex;
    align-items: stretch;
    margin-bottom: 1.5rem; }
  .dashboard__chart {
    flex: 1 1 auto; }
    .dashboard__chart:not(:last-child) {
      margin-right: .5rem; }
    .dashboard__chart._line {
      flex: 0 1 44%;
      min-width: 44%;
      max-width: 44%; }
    .dashboard__chart._pie {
      flex: 0 1 27%;
      min-width: 27%;
      max-width: 27%; }
  .dashboard__filter {
    margin-bottom: 0.75rem; }
  .dashboard__table-wrapper {
    align-self: flex-start;
    max-width: 100%; }

.main-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 1rem;
  max-width: 100%; }
  .main-content__tabs {
    margin: 1rem 0; }
  .main-content__controls {
    display: flex;
    align-items: center;
    margin: 0 -0.625rem 1.5rem; }
  .main-content__select {
    justify-content: flex-end;
    margin: 0 0.625rem; }
    .main-content__select._columns {
      display: flex;
      margin-bottom: 0;
      flex: 0 1 11.25rem;
      min-width: 11.25rem;
      max-width: 11.25rem; }
      .main-content__select._columns .select._opened {
        width: auto;
        min-width: 15.625rem; }
  .main-content__btn {
    margin: 0 0.625rem; }
  .main-content__input-range {
    margin: 0 0.625rem 1.5rem;
    width: 18.75rem; }
  .main-content__table-wrapper {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 100%; }

.manage-campaigns__selected {
  margin-bottom: 1.3125rem;
  color: #6075ff; }

.manage-campaigns__row {
  margin-bottom: .5rem; }

.manage-campaigns__label {
  display: flex;
  align-items: center;
  flex: 0 0 6.6875rem;
  min-width: 6.6875rem;
  max-width: 6.6875rem;
  margin-right: 1rem;
  font-family: 'Lato Regular', sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.2em;
  color: #000;
  white-space: nowrap; }

.manage-campaigns__control {
  flex: 1 1 auto; }

.manage-campaigns__tooltip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 0.875rem;
  min-width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  border: 0.0625rem solid #a2a2a2;
  margin-left: 0.6875rem;
  cursor: pointer;
  font-style: normal; }
  .manage-campaigns__tooltip:before {
    content: '?';
    font-family: 'Lato Bold', sans-serif;
    font-weight: 400;
    font-size: 11px;
    color: #a2a2a2; }

.editable-text {
  width: 100%;
  position: relative; }
  .editable-text .text-field__input {
    font-size: 0.875rem;
    height: 100%;
    padding: 0; }
  .editable-text .text-field__base {
    margin-top: 0; }
    .editable-text .text-field__base:after, .editable-text .text-field__base:before {
      bottom: -0.25rem; }
    .editable-text .text-field__base:after {
      border-color: #4d5ecc; }
  .editable-text .svg-icon {
    position: absolute;
    right: 0;
    width: 16px;
    height: 16px;
    fill: #4d5ecc; }
