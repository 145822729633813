body {

	.table {

		&__top {
			display: none;
			border-bottom: none;
		}

		thead {

			td {

				.tooltip {
					margin-left: .25rem;
					line-height: 0;

					&__label {
						line-height: 1;
					}

					path {
						fill: $dove-gray
					}
				}
			}
		}

		td {

			&._id {
				@include td-width(90, 90);
			}

			&._frequency-cap {
				@include td-width(140, 140);
			}

			&._total-spent {
				@include td-width(150, 150);
			}

			&._collapsed {
				@include td-width(0, 0);
				padding: 0;
				border: none;
			}

			&._max-bid,
			&._maxBid,
			&._frequency-cap,
			&._frequencyCap,
			&._bid-pacing,
			&._bidPacing {

				.td-content-inner {
					overflow: visible;
				}
			}

			&._exclude {

				.td-content {
					justify-content: center;

					.checkbox {

						svg {
							margin: 0;
						}
					}
				}
			}

			&._bidPacing {

				.td-content {
					justify-content: center;
				}
			}
		}
	}
}