.range-wrapper {

	&__label {
		display: flex;
		align-items: center;
		margin-bottom: 7/16+rem;
		@extend %text;
		color: $silver-chalice;
	}

	&__tooltip {
		margin-left: 4/16+rem;

		.tooltip {
			line-height: 0;

			&__label {
				line-height: 17/14+em;
			}
		}
	}

	&__main {
		display: flex;
		align-items: center;
		height: 36/16+rem;
	}

	&__input {
		flex: 0 1 50/16+rem;
		min-width: 50/16+rem;
		max-width: 50/16+rem;
		margin-left: 10/16+rem;
		height: 25/16+rem;
		border-radius: 2/16+rem;
		border: 1/16+rem solid #d8d8d8;
		@extend %text;
		color: $gray;
		text-align: center;

		&:focus {
			outline: none;
		}
	}
}

.range {

	&__track {
		position: relative;
		height: 36/16+rem;
		width: 100%;
		border-radius: 0;
		pointer-events: none;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			height: 5/16+rem;
			width: 100%;
			background: $melrose;
			pointer-events: auto;
		}
	}

	&__slider {
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 36/16+rem;
		height: 36/16+rem;
		border-radius: 50%;
		border: 1/16+rem solid $melrose;
		background: #fff;
		box-shadow: 0 2/16+rem 4/16+rem 0 rgba($silver-chalice, .5);
		transition: transform .25s;
		pointer-events: auto;
	}

	&__value {
		display: flex;
		justify-content: center;
		align-items: center;
		@include f1-400();
		font-size: 14/16+rem;
		line-height: 1.15;
		color: #000;
		letter-spacing: -.01em;
		text-align: center;
	}

	&__x {
		margin-bottom: 2/16+rem;
		margin-right: 2/16+rem;
		font-size: 12/16+rem;
	}
}