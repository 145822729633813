.main {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0 auto;
	max-width: none;
	min-width: 1280/16+rem;
	min-height: 100vh;
	padding: 86/16+rem 0 1rem;
}