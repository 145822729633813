* {
	box-sizing: border-box;
}

html {
	margin: 0;
	font-size: 16px;
}

body {
	margin: 0;
	@include f1-400();
}

h1, h2, h3, h4, h5, h6, p, ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

//helpers
.d-f {
	display: flex;
}

.jc-sb {
	justify-content: space-between;
}

.jc-fe {
	justify-content: flex-end;
}

.ai-fs {
	align-items: flex-start;
}

.ai-fe {
	align-items: flex-end;
}

.ai-c {
	align-items: center;
}

.ai-b {
	align-items: baseline;
}