.btn-round,
.btn-square {

	&._dodger-blue {
		@include transparent($color: #3399ff, $color-hover: $indigo);
		@include filled($background: #3399ff, $background-hover: $indigo);

		.btn-round,
		.btn-square {

			&__prefix {

				path {
					fill: #3399ff;
					transition: fill .25s;
				}
			}
		}

		&:hover {

			.btn-round,
			.btn-square {

				&__prefix {

					path {
						fill: $indigo;
					}
				}
			}
		}
	}

	&._icon-right & {

		&__prefix {
			left: auto;
			right: 10/16+rem;
		}
	}
}

.btn-close {
	position: relative;
	margin: 0;
	width: 14/16+rem;
	height: 14/16+rem;
	padding: 0;
	border: none;
	background: none;
	cursor: pointer;

	&:hover,
	&:focus {
		outline: none;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 21/16+rem;
		height: 2/16+rem;
		border-radius: 1/16+rem;
		background: $gray;
		transition: background .25s;
	}

	&:before {
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
	}

	&:after {
		transform: translateX(-50%) translateY(-50%) rotate(-45deg);
	}
}