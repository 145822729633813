@import "~factor/src/scss/variables";
@import "src/scss/components/variables";
@import "src/scss/components/mixins";
@import "src/scss/components/button";

.header {

	&Button {
		@extend %button;
		cursor: pointer;
		@extend %text;
		color: $indigo;
		text-transform: uppercase;
	}
}

.main {
	margin: .25rem .5rem 0;
	padding: 0 0 1rem;
}

.form {
	padding: 0.5rem;
}

.item {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

.label {
	flex: 0 0 100/16+rem;
	padding-right: 1rem;
	opacity: .3;
	font-size: 30/16+rem;
	line-height: 36/30+em;
	color: $mine-shaft-1;
}

.select {

}

.formInput {
	margin-left: 100/16+rem;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}