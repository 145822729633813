.sort-th {
	position: relative;
	width: 100%;

	&:before,
	&:after {
		content: '';
		position: absolute;
		right: 0;
		width: .5rem;
		height: 6/16+rem;
		background: transparent url("/img/common/sorting-arrow-down.svg") no-repeat left top;
	}

	&:before {
		bottom: 50%;
		margin-bottom: 1/16+rem;
		transform: rotate(180deg);
	}

	&:after {
		top: 50%;
		margin-top: 1/16+rem;
	}

	&._down {

		&:before {
			display: none;
		}
	}

	&._up {

		&:after {
			display: none;
		}
	}
}