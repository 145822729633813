@import "~factor/src/scss/variables";
@import "src/scss/components/variables";
@import "src/scss/components/mixins";
@import "src/scss/components/button";

.day {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

.title {
	align-self: flex-start;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-width: 62/16+rem;
	cursor: pointer;
	@include f1-700();
	font-size: .75rem;
	line-height: 15/12+em;
	color: $mine-shaft-1;
}

.sort {

	&:global {

		&.svg-icon {
			margin-left: 5/16+rem;
			min-width: 20/16+rem;
			width: 20/16+rem;
			height: auto;

			path {

				&:first-child {
					fill: #000;
				}
			}
		}
	}
}

.day {

	&:global {

		&:not(._active) {

			:local {

				.sort {
					transform: rotate(180deg);
				}

				.list {
					display: none;
				}
			}
		}
	}
}

.item {
	display: flex;
	align-items: baseline;
	margin-top: 21/16+rem;
	height: 1rem;

	&:not(:last-child) {
		margin-bottom: 21/16+rem;
	}
}

.time {
	position: relative;
	display: flex;
	align-items: center;
	min-width: 76/16+rem;

	span {
		min-width: 46/16+rem;
		font-size: 9/16+rem;
		line-height: 11/8+em;
		color: $dusty-gray;
	}

	&:after {
		content: '';
		display: block;
		min-width: 11/16+rem;
		height: 11/16+rem;
		border: 1/16+rem solid $dusty-gray;
		border-radius: 50%;
	}
}

.item:not(:last-child) {

	.time {

		&:before {
			content: '';
			position: absolute;
			top: 100%;
			right: 23/16+rem;
			width: 1/16+rem;
			height: 1.5rem;
			background: $dusty-gray;
		}
	}
}

.text {
	font-size: .75rem;
	line-height: 15/12+em;
	color: $mine-shaft-1;

	a {
		color: $mine-shaft-1;
		text-decoration: underline;
	}
}

.app {
	margin-right: 7/16+rem;
	font-size: .75rem;
	line-height: 15/12+em;
	color: $mine-shaft-1;
	text-decoration: underline;
}

.arrow {

	&:global {

		&.svg-icon {
			align-self: center;
			min-width: 15/16+rem;
			width: 15/16+rem;
			height: auto;

			path {
				fill: #000;
			}
		}
	}
}