.btn-select {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	&__title {
		@extend %button;
		position: relative;
		display: block;
		margin: -12/16+rem (-16/16)+rem 0;
		padding: 12/16+rem 32/16+rem 9/16+rem 13/16+rem;
		cursor: pointer;
		@extend %text;
		@include f1-700();
		text-align: left;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 1rem;
			border-bottom: 5/16+rem solid $gray;
			border-left: 5/16+rem solid transparent;
			border-right: 5/16+rem solid transparent;
		}
	}

	&__search {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		margin: 0 -1rem 4/16+rem;
		padding: 0 7/16+rem;
	}

	&__list {
		margin: 0 -1rem;
		padding-bottom: 10/16+rem;
		border-bottom: 1/16+rem solid $gray;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 13/16+rem;
		height: 28/16+rem;
		cursor: pointer;
		@extend %text;
		@include f1-300();

		&:hover {
			background: $wild-sand;
		}

		&._active {
			padding-right: 9/16+rem;
			background: #dfe4ff;
		}

		svg {
			width: 1rem;
			height: auto;
		}
	}

	.checkbox {

		&__label {
			@extend %text;
			@include f1-400();
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		margin: 0 -1rem;
		padding-top: 8/16+rem;

		&:not(:last-child) {
			padding-bottom: .5rem;
			border-bottom: 1/16+rem solid $gray;
		}

		&-title {
			margin-bottom: 10/16+rem;
			padding: 0 13/16+rem;
			@extend %text;
			@include f1-700();
		}
	}

	&__btn {
		@extend %button;
		padding: 0 13/16+rem;
		cursor: pointer;
		@extend %text;
		line-height: 2;
		color: $gray;
		text-align: left;

		&:not(:last-child) {
			margin-bottom: 5/16+rem;
		}

		&:hover {
			background: $wild-sand;
		}
	}
}