.filter {

	&__row {
		display: flex;
		align-items: center;

		&._pl {
			padding-left: 44/16+rem;
		}
	}

	&__left {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
	}

	&__right {
		flex: 1 1 auto;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-left: auto;

		.filter__select {
			margin-left: 0.875rem;
		}
	}

	&__checkbox {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		min-width: 44/16+rem;

		svg {
			margin: 0;
		}

		.sort-th {
			margin-left: .25rem;
			width: .5rem;
		}
	}

	&__search {
		margin-right: 13/16+rem;
		width: 160/16+rem;
	}

	&__select {
		flex: 1 1 160/16+rem;
		padding-bottom: 24/16+rem;
		max-width: 160/16+rem;
		min-width: 0;

		&._columns {
			display: flex;
			justify-content: flex-end;
			min-width: 160/16+rem;

			.select {

				&._opened {
					width: auto;
					min-width: 250/16+rem;
				}
			}
		}

		&:not(:last-child) {
			margin-right: 12/16+rem;
		}

		.select {
			width: 100%;

			&__btn-secondary {
				white-space: nowrap;
			}
		}

		&._campaigns {
			min-width: 160/16+rem;

			.select {

				&._opened {
					width: auto;
					min-width: 226/16+rem;
				}
			}
		}
	}

	&__btn-group {
		margin-right: 18/16+rem;
	}

	&__selected {
		display: flex;
		align-items: center;
		margin-right: 12/16+rem;
		@include f1-400();
		font-size: 17/16+rem;
		line-height: 21/17+em;
		color: #656565;
	}

	&__remove {
		margin-right: 6/16+rem;
		width: 18/16+rem;
		height: 18/16+rem;
		border-radius: 50%;
		background: $alto;

		&:before,
		&:after {
			width: 10/16+rem;
			height: 1/16+rem;
			background: #fff;
			border-radius: 2/16+rem;
		}
	}

	&__btn {

		&:not(:last-child) {
			margin-right: .5rem;
		}
	}
}