@import "~factor/src/scss/variables";
@import "src/scss/components/variables";
@import "src/scss/components/mixins";
@import "src/scss/components/button";

.table {

	&Btn {
		margin: 20/16+rem auto 0;
	}

	&UnderBtn {
		margin: 18/16+rem auto;
		width: 2rem;
		height: .5rem;
		border-radius: .25rem;
		background: $conflower-blue;
	}
}