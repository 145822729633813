.chart-tooltip {
	position: absolute;
	z-index: 20;
	border-radius: .25rem;
	padding: 10/16+rem 20/16+rem;
	background: #4B5661;
	transform: translate(15px, -50%);
	transition: transform ease-out .5s;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		right: 100%;
		transform: translateY(-50%);
		width: 0;
		height: 0;
		border-top: 7/16+rem solid transparent;
		border-bottom: 7/16+rem solid transparent;
		border-right: .5rem solid #4B5661;
	}

	span {
		display: block;

		&:nth-child(1) {
			margin-bottom: 7/16+rem;
			@include f1-700();
			font-size: 12/16+rem;
			line-height: 15/12+em;
			color: #BCC6D2;
		}

		&:nth-child(2) {
			@include f1-700();
			font-size: 15/16+rem;
			line-height: 18/15+em;
			color: #E0E7F0;
			white-space: nowrap;
		}
	}
}