.select {

	&._opened {
		position: relative;
		z-index: 20;
	}

	&__value {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&._opened & {

		&__btn {
			border-radius: 9/16+rem 9/16+rem 0 0;
		}
	}
}

.select-btn {

	.select {
		width: auto;

		&:not(._opened) {

			.select {

				&__btn {
					@extend .btn-round;
					@extend .btn-round._dodger-blue;
				}

				&__value {
					@extend .btn-content;
				}

				&__indicator {
					display: none;
				}
			}
		}

		&__wrapper {
			position: static;
		}
	}
}