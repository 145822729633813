.ad-input {
	padding: 0 6/16+rem;
	height: 36/16+rem;
	border-radius: 6/16+rem;
	border: 1/16+rem solid $alto;
	@extend %text;
	@include f1-300();
	color: $gray;

	&:focus {
		outline: none;
	}
}