@import "~factor/src/scss/variables";
@import "src/scss/components/variables";
@import "src/scss/components/mixins";
@import "src/scss/components/button";

.header {
	display: flex;
	align-items: center;
	padding: 0 20/16+rem;
	height: 42/16+rem;

	&:global(._pointer) {
		cursor: pointer;
	}
}

.icon {
	flex: 0 0 auto;
	width: 36/16+rem;
	height: 20/16+rem;

	svg {
		height: 100%;
		width: auto;
	}
}

.title {
	@extend %text;
	@include f1-700();
}

.right {
	margin-left: auto;
}