.dashboard {
	padding: 0 2rem;

	&__header {
		margin-bottom: 12/16+rem;
	}

	&__indicators {
		margin-bottom: 24/16+rem;
	}

	&__charts {
		display: flex;
		align-items: stretch;
		margin-bottom: 1.5rem;
	}

	&__chart {
		flex: 1 1 auto;

		&:not(:last-child) {
			margin-right: .5rem;
		}

		&._line {
			flex: 0 1 44%;
			min-width: 44%;
			max-width: 44%;
		}

		&._pie {
			flex: 0 1 27%;
			min-width: 27%;
			max-width: 27%;
		}
	}

	&__filter {
		margin-bottom: 12/16+rem;
	}

	&__table {

		&-wrapper {
			align-self: flex-start;
			max-width: 100%;
		}
	}
}